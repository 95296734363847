import React from "react"

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import Panel from "../../components/panels/panel"


const AlbumCredits = () => (
  <Layout>
    
    <Seo title="Album Credits" bodyClass="st"/>

    
    
    <div className="column column--30 alignright">
      <div className="sticky">
        <h1 className={style.titleLowercase}>Album Credits</h1>
        <p> Her Pen Game & Production is something Specyal! </p>
      </div>
    </div>
	
    <div className="column column--70">

    <div class="grid">

    <Panel 
        header={`Foresight`}
        content={`Songwriter & Main Artist`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Double Take rotated on Buzz LA: Everyday Hits & Palm Tree Indie on Spotify - 2023 
        &rarr; All Day rotated on Buzz LA: Echo Park on Spotify  - 2023 
        &rarr; All I Wanna Do rotated on Buzz LA: Undiscovered on Spotify - 2023 
        &rarr; Nite Vision rotated on Buzz LA: Melrose Ave on Spotify - 2023 
        &rarr; Double Take Single Featured in the movie ‘Alarmed’ on Tubi TV (Fox) - 2023
        &rarr; Lowkey Single Rotated on “In The Key Of C,” Up_To_Speed,” “Daybreak” & “Homestretch” CBC Radio 1 & “Wednesday Morning After” CKUT 90.3 FM - 2022
        &rarr; Featured on “The Eh List,” “New Sounds 2022” Songwriters Association Of Canada, “R&B You Found” & “Groover Playlist 149: Springing Forward” on Spotify - 2022
        &rarr; Gained 1K+ plays on Spotify in the first month - 2022
        &rarr; In T’s Top 10 on Apple Music - 2022
        &rarr; Double Take Single Rotated on Humber Radio 96.9 FM & Ingrown Radio - 2022
        &rarr; Featured on “The Eh List,” “R&B You Found” & “ Groover Playlist 153” on Spotify - 2022
        &rarr; In T’s Top 10 on Apple Music - 2022
        `}
        footer={`Released: 2023`}
        />
    <Panel 
        header={`Slight Detour EP`}
        content={`Songwriter & Main Artist`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Midnight Shade Single Rotated on “Wednesday Morning After” CKUT 90.3 FM - 2022
        &rarr; Midnight Shade Single debuted in the #1 Spot and remained in T’s Top 5 on Spotify after it’s release - 2021
        `}
        footer={`Released: 2021`}
        />
    <Panel 
        header={`A Specyal Remedy`}
        content={`Songwriter & Main Artist`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Three Rotated on CBC Radio 1 Edmonton 93.9 FM - 2022 
        &rarr; Even Tho Rotated on “Setting The Standard” KPFA 94.1 FM - 2022
        &rarr; Box Food Single played in 10 countries and was added to 17 Playlists on Spotify - 2020 
        &rarr; Box Food Video has 4K+ views on Facebook
        `}
        footer={`Released: 2020`}
        />
    <Panel 
        header={`Girl Friday EP`}
        content={`Songwriter & Main Artist`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Girl Friday Album has 8K+ streams on Spotify 
        &rarr; Just Desserts & O Therapy remain in T’s Top 5 on Apple Music - 2019-Present 
        &rarr; Sunday Siesta Single Reached 5K views on YouTube in first week of release - 2019
        &rarr; Rotated on Womenincharg3 Radio - 2019
        `}
        footer={`Released: 2019`}
        />
    <Panel 
        header={`Tionne EP`}
        content={`Songwriter & Main Artist`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Side Effects Single won Best Rap / Hip-Hop Song - Indie Music Awards LA - 2017
        &rarr; Side Effects Video has 4K+ views and has been shared over 200x on Facebook `}
        footer={`Released: 2017`}
        />
    <Panel 
        header={`C.A.K.E. EP`}
        content={`Songwriter & Main Artist`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Just A Thot remains T’s Top Song on Apple Music - 2021-Present
        &rarr; Just A Thot Single Stayed at #1 on Power XR Rap / Hip-Hop Radio Charts during the month of January 2017
        &rarr; C.A.K.E. Album won Best Rap / Electronica EP - Indie Music Awards LA - 2015
        &rarr; Stay With Me Single Rotated on Womenincharg3 Radio - 2015
        &rarr; Just A Thot Single Rotated on “The Best Kept Secret” Excitement Radio - 2015
        `}
        footer={`Released: 2015`}
        />
    <Panel 
        header={`Dose Of Distinction 2`}
        content={`Composer / Producer / Songwriter & Main Artist`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Almost Lover Video was directed by Alex Hatz (award-winning film maker)
        &rarr; Almost Lover Single & Video Rotated on Tone Flame’s Radio & Video Channels - 2015
        `}
        footer={`Released: 2014`}
        />
    <Panel 
        header={`Go Tell Them EP - Catherine Marie`}
        content={`Composer / Producer & Songwriter
        Go Tell Them (Groove To This) / Lie To Me & Classified
        Co-Songwriter - When The Lightening Strikes
        Featured Artist - Go Tell Them (Groove To This) & Lie To Me
        Pianist & Arranger - Just A Little (piano version)`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Lie To Me Single remains in T’s Top 10 on Apple Music - 2019-Present
        &rarr; Lie To Me Single charted on various North American DJ Pools - 2012
        &rarr; Go Tell Them (Groove To This) Single Rotated on Virgin Radio Vancouver - 2012`}
        footer={`Released: 2012`}
        />
    <Panel 
        header={`She Said! EP`}
        content={`Composer / Producer & Songwriter (entire album) & Main Artist`}
        footer={`Released: 2011`}
        />
    <Panel 
        header={`Dose Of Distinction`}
        content={`Composer / Producer / Songwriter & Main Artist`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Ice Cream Cone Video Rotated on Much Vibe - 2009 / 2010
        &rarr;  Dose Of Distinction Album charted on Vibe 105.5’s Top 30 Charts - 2012
        &rarr;  Get Me Thru Single Nominated for Best Hip-Hop Song - Toronto Independent Magazine - 2010`}
        footer={`Released: 2010`}
        />
    <Panel 
        header={`Sweat It Up! EP`}
        content={`Songwriter & Main Artist`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Sweat It Up! Album Charted on various North American DJ Pools - 2009 / 2010`}
        footer={`Released: 2009`}
        />
    <Panel 
        header={`That Was Clever! - Specyal T Productions`}
        content={`Composer / Producer & Songwriter (entire album) & Main Artist`}
        footer={`Released: 2008`}
        />
    <Panel 
        header={`Ontario In Your Stereo EP`}
        content={`Composer / Producer & Songwriter (entire album) & Main Artist`}
        innerpanel={`<strong>Highlights</strong>
        &rarr; Ice Cream Cone Single Rotated on various collage radio stations - 2009`}
        footer={`Released: 2008`}
        />
         </div>

      </div>

  </Layout>
)

export default AlbumCredits
